import { FormattedMessage, useIntl } from 'react-intl';
import TriSunca2015 from '../assets/trisunca-traminac-2015.webp';
import TriSunca2016 from '../assets/trisunca-traminac-2016.webp';
import TriSuncaGrasac from '../assets/trisunca-grasac-2019.webp';
import WineListPage from '../components/molecules/WineListPage/WineListPage';
import { useEffect } from 'react';
import TriSuncaLogo from '../assets/trisunca-logo.webp';
import BackgroundImage from '../assets/trisunca-bg.webp';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const TriSunca = () => {
    const intl = useIntl();
    const location = useLocation();
    const canonicalUrl = `https://fruvin.com${location.pathname}`;

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    const wines = [
        {
            id: 1,
            image: TriSunca2015,
            name: <FormattedMessage id='trisunca__traminac_2015_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='trisunca__traminac_2015_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='trisunca__traminac_2015_liter' defaultMessage="Missing translation value" />,
            urlName: 'trisunca-traminac-2015',
        },
        {
            id: 2,
            image: TriSunca2016,
            name: <FormattedMessage id='trisunca__traminac_2016_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='trisunca__traminac_2016_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='trisunca__traminac_2016_liter' defaultMessage="Missing translation value" />,
            urlName: 'trisunca-traminac-2016',
        },
        {
            id: 3,
            image: TriSuncaGrasac,
            name: <FormattedMessage id='trisunca__grasac_2019_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='trisunca__grasac_2019_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='trisunca__grasac_2019_liter' defaultMessage="Missing translation value" />,
            urlName: 'trisunca-grasac-2019',
        }
    ];

    return (
        <>
            <Helmet>
                <title>Tri Sunca | Fruškogorski vinogradi</title>
                <meta name="description" content="Tri Sunca, nagrađivano vino iz vinarije Fruškogorski vinogradi na Fruškoj gori, osvojilo je priznanja na domaćim i svetskim takmičenjima, uključujući prestižnu titulu za najbolje srpsko slatko vino. Uživajte u premijum ukusu obogaćenom sunčevom svetlošću s neba, zemlje i Dunava." />
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <WineListPage
                type={'trisunca'}
                backgroundImage={BackgroundImage}
                descriptionText={intl.formatMessage({ id: 'trisunca__description', defaultMessage: 'Missing translation' })}
                wineLogo={TriSuncaLogo}
                wines={wines}
            />
        </>
    );
}

export default TriSunca;